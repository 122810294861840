<template>
  <div>
    <b-row v-if="subscriptions.subscription" class="mb-2">
      <b-col cols="12" sm="12" md="6" lg="4">
        <b-card
          class="h-100 shadow"
          style="border-radius: 12px"
          border-variant="primary"
          body-class="d-flex flex-column"
        >
          <div class="d-flex justify-content-center my-2">
            <i class="fas fa-calendar-check fa-4x"></i>
          </div>

          <div>
            <div class="mt-2 h4" style="color: #7367f0; font-weight: bold">
              {{ subscriptions.subscription.plan.product_name }}
            </div>
          </div>

          <div>
            <b-badge
              v-if="subscriptions.subscription.stripe_status == 'active'"
              variant="success"
              >Activa</b-badge
            >
            <b-badge
              v-else-if="subscriptions.subscription.stripe_status == 'trialing'"
              variant="warning"
              >Prueba</b-badge
            >
            <b-badge v-else variant="danger">Inactiva</b-badge>
          </div>
          <div class="mt-1">
            Vigencia:
            {{ formatDate(subscriptions.subscription.valid_from) }} -
            {{ formatDate(subscriptions.subscription.valid_until) }}
          </div>
          <b-alert
            v-if="
              subscriptions.subscription.stripe_status != 'active' &&
              subscriptions.subscription.stripe_status != 'trialing'
            "
            show
            variant="danger"
            class="p-2 rounded"
            style="margin: 1rem 0"
          >
            Alerta: Revise en el portal de facturación sus métodos de pago. No
            podrá realizar movimientos mientras su suscripción esté inactiva.
          </b-alert>

          <b-alert
            v-if="subscriptions.subscription.cancel_at_period_end"
            show
            variant="warning"
            class="p-2 rounded"
            style="margin: 1rem 0"
          >
            Atención: Su suscripción está marcada como cancelada y terminará el
            {{ formatDate(subscriptions.subscription.cancel_at) }}
          </b-alert>

          <div>
            <hr class="mt-3" />
            <ul
              v-if="subscriptions.subscription.plan.description"
              class="description__list"
              style="text-align: left"
            >
              <li v-for="(item, index) in getDescriptionArray()" :key="index">
                <feather-icon icon="CheckIcon" size="16" class="mr-1" />
                {{ capitalize(item) }}
              </li>
            </ul>
            <hr class="mb-3" />
          </div>

          <div style="color: #7367f0; font-weight: bold; font-size: 16px">
            ${{ subscriptions.subscription.plan.price | money }} MXN /
            {{ subscriptions.subscription.plan.interval }}
          </div>

          <div class="mt-auto">
            <b-button
              variant="primary"
              class="mb-2 btn-block"
              @click="
                getUrlPortalSession(
                  subscriptions.subscription.stripe_subscription_id
                )
              "
            >
              Portal de facturación
            </b-button>
          </div>
        </b-card>
      </b-col>

      <b-col
        v-for="item in subscriptions.store_plan"
        :key="item.id"
        cols="12"
        sm="12"
        md="6"
        lg="4"
      >
        <b-card
          class="shadow h-100"
          style="border-radius: 12px"
          border-variant="primary"
          body-class="d-flex flex-column"
        >
          <div class="d-flex justify-content-center my-2">
            <i class="fas fa-store fa-4x"></i>
          </div>

          <div class="mb-2">
            <div class="mt-2 h4" style="color: #7367f0; font-weight: bold">
              Agregar tienda
            </div>

            <div
              v-if="parseFloat(item.coupon_discount) == 0"
              class="mt-2"
              style="color: #7367f0; font-weight: bold; font-size: 16px"
            >
              ${{ item.price }} MXN / {{ item.interval }}
            </div>

            <div
              class="price__container"
              style="color: #7367f0 !important; font-weight: bold"
            >
              <span style="color: #28c76f; font-size: 24px">
                ${{ discountedPrice(item.price, item.coupon_discount) | money }}
              </span>

              <div>
                <span style="text-decoration: line-through; color: #7367f0">
                  ${{ item.price }}
                </span>
                <span> MXN {{ item.interval }} </span>
              </div>
            </div>
          </div>

          <div>
            <hr />
            <ul v-if="item.description" class="description__list">
              <li v-for="(descItem, index) in item.description" :key="index">
                <feather-icon icon="CheckIcon" size="16" class="mr-1" />
                {{ descItem }}
              </li>
            </ul>
            <hr />
          </div>

          <b-button
            block
            class="mt-auto"
            variant="success"
            @click="getUrlCheckoutSession(item.stripe_plan_id)"
          >
            Seleccionar plan
          </b-button>
        </b-card>
      </b-col>

      <b-col
        v-for="item in subscriptions.user_plans"
        :key="item.id"
        cols="12"
        sm="12"
        md="6"
        lg="4"
      >
        <b-card
          class="shadow h-100"
          style="border-radius: 12px"
          border-variant="primary"
          body-class="d-flex flex-column"
        >
          <div class="d-flex justify-content-center my-2">
            <i class="fas fa-store fa-4x"></i>
          </div>

          <div class="mb-2">
            <div class="mt-2 h4" style="color: #7367f0; font-weight: bold">
              Agregar usuario
            </div>

            <div
              v-if="parseFloat(item.coupon_discount) == 0"
              style="color: #7367f0; font-weight: bold"
              class="price__container"
            >
              <span style="color: #28c76f; font-size: 24px">
                ${{ item.price }}
              </span>
              <span> MXN / {{ item.interval }} </span>
            </div>

            <div
              v-else
              class="price__container"
              style="color: #7367f0 !important; font-weight: bold"
            >
              <span style="color: #28c76f; font-size: 24px">
                ${{ discountedPrice(item.price, item.coupon_discount) | money }}
              </span>

              <div>
                <span style="text-decoration: line-through; color: #7367f0">
                  ${{ item.price }}
                </span>
                <span> MXN {{ item.interval }} </span>
              </div>
            </div>
          </div>

          <div>
            <hr />
            <ul v-if="item.description" class="description__list">
              <li v-for="(descItem, index) in item.description" :key="index">
                <feather-icon icon="CheckIcon" size="16" class="mr-1" />
                {{ descItem }}
              </li>
            </ul>
            <hr />
          </div>

          <div class="mt-auto">
            <b-button
              block
              variant="success"
              @click="getUrlCheckoutSession(item.stripe_plan_id)"
            >
              Seleccionar plan
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-else>
      <b-col
        v-for="item in subscriptions.general_plans"
        :key="item.id"
        cols="12"
        sm="12"
        md="6"
        lg="4"
      >
        <b-card
          class="shadow d-flex flex-column h-100"
          style="border-radius: 12px"
          border-variant="primary"
        >
          <div class="d-flex justify-content-center pt-50 pb-2">
            <i class="fas fa-calendar-check fa-4x"></i>
          </div>
          <div class="mb-2">
            <div
              class="h4"
              style="color: #7367f0 !important; font-weight: bold"
            >
              {{ item.product_name }}
            </div>

            <div
              v-if="parseFloat(item.coupon_discount) == 0"
              class="mt-2"
              style="color: #7367f0; font-weight: bold; font-size: 16px"
            >
              ${{ item.price }} MXN / {{ item.interval }}
            </div>

            <div
              v-else
              class="price__container"
              style="color: #7367f0 !important; font-weight: bold"
            >
              <span style="color: #28c76f; font-size: 24px">
                ${{ discountedPrice(item.price, item.coupon_discount) | money }}
              </span>

              <div>
                <span style="text-decoration: line-through; color: #7367f0">
                  ${{ item.price }}
                </span>
                <span> MXN {{ item.interval }} </span>
              </div>
            </div>
          </div>

          <hr />
          <ul v-if="item.description" class="description__list">
            <li v-for="(item, index) in item.description" :key="index">
              <feather-icon icon="CheckIcon" size="16" class="mr-1" />
              {{ item }}
            </li>
          </ul>
          <hr />

          <b-button
            block
            class="mt-3"
            variant="success"
            @click="getUrlCheckoutSession(item.stripe_plan_id)"
          >
            Seleccionar plan
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import axios from "@axios"
import { formatDate } from "@core/utils/filter"
import subscriptions from "@/store/subscriptions"

export default {
  name: "Subscriptions",
  components: {},
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      formatDate,
    }
  },
  beforeMount() {
    this.fetchSubscription(this.$route.params.id).then((res) => {
      if (
        !res ||
        (res.stripe_status !== "active" && res.stripe_status !== "trialing")
      ) {
        this.$swal({
          title: "Atención",
          text: "No cuentas con una suscripción activa. Ten en cuenta que no podrás registrar ventas en el sistema. Si deseas contratar un plan, dirígete a la sección de planes y selecciona el que mejor se ajuste a las necesidades de tu negocio",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        })
      }
    })
    this.fetchPlans()
  },
  methods: {
    ...mapActions("subscriptions", ["fetchPlans", "fetchSubscription"]),

    discountedPrice(price, discount) {
      return (
        parseFloat(price.replace(/[^0-9.]+/g, "")) *
        (1 - parseFloat(discount.replace(/[^0-9.]+/g, "")) / 100)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      )
    },

    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    async getUrlCheckoutSession(plan_id) {
      const url = `${process.env.VUE_APP_API_URL}/v1/stripe/checkout_session/${this.$route.params.id}/${plan_id}`
      const result = await axios.get(url, {
        params: {
          "checkout_session[quantity]": 1,
        },
      })
      window.location.href = result.data.session_url
      return
    },
    async getUrlPortalSession(subscription_id) {
      const url = `${process.env.VUE_APP_API_URL}/v1/stripe/billing_portal/${this.$route.params.id}/${subscription_id}`
      const result = await axios.get(url)
      window.location.href = result.data.portal_session_url
      return
    },
    getDescriptionArray() {
      if (
        typeof this.subscriptions.subscription.plan.description === "string"
      ) {
        return this.subscriptions.subscription.plan.description.split(", ")
      } else {
        return []
      }
    },
  },
  computed: {
    ...mapState(["subscriptions"]),
  },
}
</script>

<style lang="scss" scoped>
.store__card {
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease;

  // &:hover {
  //   transform: translateY(-5px);
  // }
}
.add__icon {
  background-color: rgb(89, 214, 89);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  color: white;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.gutter-md {
  gap: 1rem;
}

.price__container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.description__list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 3rem;
  margin-bottom: 3rem;

  li {
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}
</style>
